<template>
<div>
      <b-skeleton-table v-if="loading" :columns="2" :rows="8"></b-skeleton-table>
      <b-container v-else :key="ticks">
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Långt namn</b-col>
                  <b-col>
                        <b-input v-model="group.name" placeholder="Gruppnamn..."></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Kort namn</b-col>
                  <b-col>
                        <b-input v-model="group.shortname" placeholder="Kort gruppnamn..."></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Slutspelsgrupp?</b-col>
                  <b-col>
                        <b-checkbox value="1" v-model="group.grouptype"></b-checkbox>
                  </b-col>
            </b-row>
            <b-row v-if="group.grouptype > '0'">
                  <b-col class="d-flex">
                        <div @click="HandleGroupClick(g)" v-for="g in possiblegroups" :key="g.id" :class="groupgroupClass(g)" class="group-group">
                              {{g.name}}
                        </div>
                  </b-col>
            </b-row>

            <b-row class="mt-2" v-if="haserror">
                  <b-col>
                        <Alert variant="danger" v-for="(err, index) in terrors" :key="index" >{{err.error}}</Alert>
                  </b-col>
            </b-row>

            <hr />

            <b-row class="mb-1 mt-2">
                  <b-col class="text-center">
                        <b-button class="mx-1" v-if="!hidecancel" @click="Cancel()" variant="secondary">Avbryt</b-button>
                        <b-button class="mx-1" @click="AddGroup()" variant="primary">Spara</b-button>
                  </b-col>
            </b-row>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
export default {
      Name: "AddEditGroup",
      props: {
            "group": Object,
            "groups": Array,
            "hidecancel": Boolean
      },
      components: {
            Alert
      },
      data() {
            return {
                  terrors: [],
                  ticks: 1,
            }
      },
      computed: {
            loading() {
                  return this.$store.getters["adminpost/grouploading"];
            },
            haserror() {
                  return this.$store.getters["adminpost/grouperror"];
            },
            possiblegroups() {
                  return this.groups.filter((g) => {
                        return g.id != this.group.id
                  });
            }
      },
      methods: {
            groupgroupClass(group) {
                  var c = "";
                  if (this.IsUsed(group.id))
                        c += " used";
                  if (group.grouptype > '0')
                        c += " end-group"; // slutspelsgrupp
                  return c;
            },
            IsUsed(groupid) {
                  if (this.group.groups) {
                        for (var i = 0; i < this.group.groups.length; i++)
                              if (this.group.groups[i].childgroupid == groupid)
                                    return true;
                  }
                  return false;
            },
            AddGroup() {
                  this.$store.dispatch("adminpost/AddGroup", this.group).then((response) => {
                        if (response.status == "ok") {
                              this.$emit("saved");
                        } else {
                              this.terrors = response.errors;
                        }
                  });
            },
            Cancel() {
                  this.$emit("cancel");
            },
            HandleGroupClick(group) {
                  // check if add or remove                  
                  if (this.IsUsed(group.id)) {
                        // remove
                        this.removeGroupGroup(group);
                  }
                  else 
                        this.addGroupGroup(group);
                 this.ticks += 1;
            },
            addGroupGroup(group) {
                  if(!this.group.groups){
                        this.group.groups = [];
                  }

                  this.group.groups.push({
                        groupid: this.group.id,
                        childgroupid: group.id
                  });
            },
            removeGroupGroup(group) {
                  var newlist = [];
                  if (this.group.groups) {
                        for (var i = 0; i < this.group.groups.length; i++) {
                              if (this.group.groups[i].childgroupid != group.id) {
                                    newlist.push(this.group.groups[i]);
                              }
                        }
                        if(newlist.length > 0)
                              this.group.groups = newlist;
                        else
                              this.group.groups = null;
                  }
            }
      },
};
</script>
